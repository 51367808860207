html,
body {
  margin: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.QRDisplay {
  margin: 20px;
}

.qr-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

h1 {
  color: #333;
}
